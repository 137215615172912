import React, { PureComponent } from "react";
import { ListGroup, ListGroupItem, Container, Row, Nav, Card, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBreadSlice } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from "react-router-bootstrap";
import { API, Auth } from "aws-amplify";
import Tags from "../components/Tags";
import LoaderButton from "../components/LoaderButton";
import "./Home.css";

export default class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        title: '',
        keywords: []
      },
      suggestions: [],
      suggestionButtons: [],
      hasResults: false,
      isLoading: false,
      newTag: null,
      results: []
    };
  }

  async componentDidMount() {
   await Auth.currentSession();
    try {
      const tags = await API.get("recipes", "/tags");
      this.setState({suggestions: tags,
                     suggestionButtons: tags});
    } catch (e) {
      alert(e);
    }
  }

  setIsLoading(loading) {
    this.setState({isLoading: loading});
  }

  validateForm() {
    return this.state.form.title.length > 0 | this.state.form.keywords.length > 0;
  }

  tagStateMirror(tagState) {
    this.setState({form: {...this.state.form, keywords: tagState},
                   newTag: null});
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({form: {...this.state.form, [fieldName]: fleldVal},
                   newTag: null});
  }

  addTagFromSuggestion(idx, tag) {
    var suggestionButtons = this.state.suggestionButtons
    suggestionButtons.splice(idx, 1);
    var keywords = this.state.form.keywords;
    keywords.push(tag);
    this.setState({form: {...this.state.form, keywords: keywords},
                   newTag: tag,
                   suggestionButtons: suggestionButtons});
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({newTag: null});
    console.log(this.state);
    this.setIsLoading(true);
    try {
      await this.doSearch();
    } catch (e) {
      alert(e);
    }
    this.setIsLoading(false);
  }

  async doSearch() {
    var url = "/search";
    var keywordsMap = this.state.form.keywords.map(encodeURIComponent)
    var keywordsStr = keywordsMap.join("+");
    if (this.state.form.title!=="") {
      url += "?title=" + encodeURIComponent(this.state.form.title)
      if (keywordsStr!=="") {
        url += "&keywords=" + keywordsStr;
      }
    } else if (keywordsStr!=="") {
      url += "?keywords=" + keywordsStr;
    }
    console.log(url);
    if (url === '/search') {
      console.log('no search terms!')
    } else {
      var results = await API.get("recipes", url);
      console.log(results);
      this.setState({results: results});
    }
  }

  renderRecipesList() {
    return this.state.results.map((recipe, i) =>
        <LinkContainer key={recipe.recipeId} to={`/recipes/view/${recipe.recipeId}`}>
          <ListGroupItem>
            <h5 className="mb-1">{recipe.title.trim()}</h5>
            {recipe.description}
            <i>{recipe.keywords}</i>
          </ListGroupItem>
        </LinkContainer>
    );
  }

  renderSuggestionButtons() {
    return this.state.suggestionButtons.map((suggestion, i) =>
      <i key={"suggestion_i_"+i}>
        <a key={"suggestion_button_"+i} href="/search" onClick={this.addTagFromSuggestion.bind(this, i, suggestion)}>{suggestion}</a>&nbsp;&nbsp;
      </i>
    );
  }

  renderPage() {
    return (
      <div className="page">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Card>
            <Card.Body>
              <FormGroup className="row" controlId="content">
                <FormLabel className="col-sm-2 col-form-label">Title</FormLabel>
                <div className="col-sm-10">
                  <FormControl
                    type='text'
                    name='title'
                    defaultValue={this.state.form.title}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" controlId="content">
                <FormLabel className="col-sm-2 col-form-label">Keywords</FormLabel>
                <div className="col-sm-10">
                  <Tags allowNew={false} tagStateMirror={this.tagStateMirror.bind(this)} tags={this.state.form.keywords} suggestions={this.state.suggestions} newTag={this.state.newTag}/>
                  {this.renderSuggestionButtons()}
                </div>
              </FormGroup>

              <LoaderButton
                block
                type="submit"
                bssize="large"
                bsstyle="primary"
                isLoading={this.state.isLoading}
                disabled={!this.validateForm()}
              >
                Search
              </LoaderButton>
            </Card.Body>
          </Card>
        </form>

        &nbsp;

        <div className="results">
          <br/>
          <ListGroup>
            {this.renderRecipesList()}
          </ListGroup>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Container>
        <Row>
          <div className="col-xs-05">
            <Nav className="d-none d-sm-block bg-secondary sidebar">
              <div className="sidebar-sticky"></div>
              <Nav.Item>
                <Nav.Link href="/"><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/></Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-xs-115">
            {this.renderPage()}
          </div>
        </Row>
      </Container>
    );
  }

}

