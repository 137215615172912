import React, { PureComponent } from "react";
import { Card, FormCheck, FormGroup, FormControl, FormLabel, Row, Nav, Container, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBreadSlice, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Ingredients from "../components/Ingredients";
import Steps from "../components/Steps";
import Tags from "../components/Tags";
import { API, Auth } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import "./Recipes.css";


// We store the steps and ingredients here so that they can be accessed during a submit event.
// But the child elements also store them, because the re-render takes place based on their
// state changing.
// It means we're storing everything twice, but that's (probably) fine.

// TODO: Keywords, made - <Form.Check aria-label="option 1" />
// TODO: Handle submit success/error (toast?)


export default class EditRecipe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showDelete: false,
      recipeId: props.match.params.id,
      form: {
        title: '',
        description: '',
        yield_: 0,
        made: false,
        prepTime: 0,
        cookTime: 0,
        keywords: [],
        source_: '',
        notes: '',
        image: null
      },
      steps: {},
      ingredients: {},
      suggestions: [],
      imgUpdate: 'keep'
    };
    this.fileRef = React.createRef();
  }

  async componentDidMount() {
    await Auth.currentSession();
    try {
      var recipe = await API.get("recipes", `/recipe/${this.state.recipeId}`);
      console.log(recipe);
      var formDetails = {title: recipe.title,
                         description: recipe.description,
                         yield_: recipe.yield_,
                         made: recipe.made,
                         prepTime: recipe.prepTime,
                         cookTime: recipe.cookTime,
                         keywords: recipe.keywords,
                         source_: recipe.source_,
                         notes: recipe.notes,
                         image: recipe.image}
      this.setState({form: formDetails,
                     ingredients: recipe.ingredients,
                     steps: recipe.steps,
                     isLoading: false});
      const tags = await API.get("recipes", "/tags");
      this.setState({suggestions: tags});
    } catch (e) {
      alert(e);
    }
  }

  handleBack() {
    this.props.history.goBack()
  }

  setIsLoading(loading) {
    this.setState({isLoading: loading});
  }

  validateForm() {
    return this.state.form.title.length > 0;
  }

  handleImageChange(value) {
    this.setState({imgUpdate: value});
  };

  handleFileChange = event => {
    this.fileRef.current = event.target.files[0];
    this.setState({form: {...this.state.form, image: true}});
  };

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    this.setIsLoading(true);
    try {
      await this.updateRecipe();
      if (this.state.imgUpdate === 'update' && this.fileRef.current) {
        await s3Upload(this.fileRef.current, this.state.recipeId);
      }
      this.props.history.push("/recipes/view/"+this.state.recipeId);
    } catch (e) {
      alert(e);
      this.setIsLoading(false);
    }
  }

  async handleCancel(event) {
    event.preventDefault();
    this.props.history.push("/recipes/view/"+this.state.recipeId);
  }

  handleDelete1(event) {
    this.setState({showDelete: true});
  }

  async handleDelete2(event) {
    API.del("recipes",
            "/recipe/"+this.state.recipeId,
            {
              body: {}
            }
    );
    this.props.history.go(-2);
  }

  handleCloseDelete() {
    this.setState({showDelete: false});
  }

  updateRecipe() {
    var recipeInfo = this.state.form;
    // Set the image value
    if (this.state.imgUpdate === 'delete') {
      recipeInfo.image = false;
    }
    if (this.state.imgUpdate === 'update' && this.fileRef.current) {
      recipeInfo.image = true;
    }
    console.log(recipeInfo);
    return API.put("recipes",
                    "/recipe/"+this.state.recipeId,
                    {
                      body: {
                        info: recipeInfo,
                        ingredients: this.state.ingredients,
                        steps: this.state.steps
                      }
                    }
    );
  }

  ingredientStateMirror(ingredientState) {
    this.setState({ingredients: ingredientState});
  }

  stepStateMirror(stepState) {
    this.setState({steps: stepState});
  }

  tagStateMirror(tagState) {
    this.setState({form: {...this.state.form, keywords: tagState}});
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({form: {...this.state.form, [fieldName]: fleldVal}});
  }

  renderImageSelector() {
    if (this.state.imgUpdate === 'update') {
      return <FormControl onChange={this.handleFileChange.bind(this)} type="file" />
    } else {
      return <></>
    }
  }

  renderRecipeForm() {
    return (
     <div className="p-3">
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Card>
          <Card.Body>
            <h3>Recipe details</h3>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Title</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='text'
                  name='title'
                  defaultValue={this.state.form.title}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Description</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='text'
                  name='description'
                  defaultValue={this.state.form.description}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Makes</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='number'
                  name='yield_'
                  defaultValue={this.state.form.yield_}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Prep Time</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='number'
                  name='prepTime'
                  defaultValue={this.state.form.prepTime}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Cook Time</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='number'
                  name='cookTime'
                  defaultValue={this.state.form.cookTime}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Made</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  as='select'
                  name='made'
                  defaultValue={this.state.form.made}
                  onChange={this.handleChange.bind(this)}
                >
                  <option>Yes</option>
                  <option>No</option>
                </FormControl>
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Keywords</FormLabel>
              <div className="col-sm-10">
                <Tags allowNew={true} tagStateMirror={this.tagStateMirror.bind(this)} tags={this.state.form.keywords} suggestions={this.state.suggestions}/>
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Source</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='text'
                  name='source_'
                  defaultValue={this.state.form.source_}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Notes</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  as='textarea'
                  name='notes'
                  rows="2"
                  defaultValue={this.state.form.notes}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
          </Card.Body>
        </Card>

        &nbsp;

        <Card>
          <Card.Body>
            <h3>Ingredients</h3>
            <Ingredients ingredients={this.state.ingredients} ingredientStateMirror={this.ingredientStateMirror.bind(this)} />
          </Card.Body>
        </Card>

        &nbsp;

        <Card>
          <Card.Body>
            <h3>Method</h3>
            <Steps steps={this.state.steps} stepStateMirror={this.stepStateMirror.bind(this)} />
          </Card.Body>
        </Card>

        &nbsp;

        <Card>
          <Card.Body>
            <h3>Image</h3>
            <FormCheck
              type='radio'
              name='img-keep'
              label='Keep existing image'
              checked={this.state.imgUpdate === 'keep'}
              onChange={this.handleImageChange.bind(this, 'keep')}
            />
            <FormCheck
              type='radio'
              name='img-update'
              label='Update image'
              checked={this.state.imgUpdate === 'update'}
              onChange={this.handleImageChange.bind(this, 'update')}
            />
            <FormCheck
              type='radio'
              name='img-delete'
              label='Delete image'
              checked={this.state.imgUpdate === 'delete'}
              onChange={this.handleImageChange.bind(this, 'delete')}
            />
            {this.renderImageSelector()}
          </Card.Body>
        </Card>

        &nbsp;

        <div className="row">
          <div className="col-sm-4">
            <LoaderButton
              block
              type="button"
              bssize="large"
              className="btn-danger"
              onClick={this.handleDelete1.bind(this)}
            >
              Delete
            </LoaderButton>
          </div>
          <div className="col-sm-4">
            <LoaderButton
              block
              type="button"
              bssize="large"
              className="btn-secondary"
              onClick={this.handleCancel.bind(this)}
            >
              Cancel
            </LoaderButton>
          </div>
          <div className="col-sm-4">
            <LoaderButton
              block
              type="submit"
              bssize="large"
              bsstyle="primary"
              isLoading={this.state.isLoading}
              disabled={!this.validateForm()}
            >
              Save
            </LoaderButton>
          </div>
        </div>
      </form>

    </div>
    );
  }

  renderPage() {
    return (
      <div className="Home">
        {!this.state.isLoading && this.renderRecipeForm()}
      </div>
    );
  }

  render() {
    return (
      <Container>
        <Row>
          <div className="col-xs-05">
            <Nav className="d-none d-sm-block bg-secondary sidebar">
              <div className="sidebar-sticky"></div>
              <Nav.Item>
                <LinkContainer to="/"><Nav.Link><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/></Nav.Link></LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={this.handleBack.bind(this)}><FontAwesomeIcon icon={faArrowAltCircleLeft} className="fa-fw"/></Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-xs-115">
            {this.renderPage()}
          </div>
        </Row>

        <Modal show={this.state.showDelete} onHide={this.handleCloseDelete.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this recipe?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseDelete.bind(this)}>Cancel</Button>
            <Button variant="danger" onClick={this.handleDelete2.bind(this)}>Delete</Button>
          </Modal.Footer>
        </Modal>

      </Container>
    );
  }
}


//
//  handleFileChange(event) {
//    file.current = event.target.files[0];
//  }

//  async handleSubmit(event) {
//    event.preventDefault();
//
////    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
////      alert(
////        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
////          1000000} MB.`
////      );
////      return;
////    }
//
//    setIsLoading(true);
//    try {
//      const attachment = file.current
//        ? await s3Upload(file.current)
//        : null;
//      await createRecipe({ title, attachment });
//      props.history.push("/");
//    } catch (e) {
//      alert(e);
//      setIsLoading(false);
//    }
//  }

//  setValue(field, value) {
//    this.setState(field] = value;
//  }

//  createRecipe(note) {
//    return API.post("notes", "/notes", {
//      body: {"title" : title,
//      }
//    });
//  }

// <div className="form-group">
//          <label htmlFor="inputPostUrl">POST URL</label>
//          <input type="text" className="form-control" id="inputPostUrl" placeholder="Enter URL" value={postUrl} onChange={e => setPostUrl(e.target.value)} />
//          <small id="inputPostUrlHelp" className="form-text text-muted">Specify a URL that we'll update any time the status changes.</small>
//        </div>