import React, { PureComponent } from "react";
import "./Home.css";
import { Nav, Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBreadSlice, faEdit, faList, faSearch } from '@fortawesome/free-solid-svg-icons'

// Main function
export default class MainPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ws: null
    };
  }

  render() {
    return (
      <Container>
        <Row>
          <div className="col-xs-05">
            <Nav className="d-none d-sm-block bg-secondary sidebar">
              <div className="sidebar-sticky"></div>
              <Nav.Item>
                <LinkContainer to="/"><Nav.Link><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/></Nav.Link></LinkContainer>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-xs-115">
            <Row>
              <Col className="align-self-center text-center">
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col className="align-self-center text-center">
                <h1 className="display-4"><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/>  Recipes</h1>
              </Col>
            </Row>
            <Row>
              &nbsp;
            </Row>
            <Row>
              <Col xs={2} className="align-self-center">
                &nbsp;
              </Col>
              <Col xs={3} className="align-self-center">
                <LinkContainer to="/recipes/new" className="btn btn-squared-default-plain btn-primary">
                  <div>
                    <FontAwesomeIcon icon={faEdit} className="fa-5x"/>
                    <br />
                    <br />
                    Create
                  </div>
                </LinkContainer>
              </Col>
              <Col xs={3} className="align-self-center">
                <LinkContainer to="/recipes" className="btn btn-squared-default-plain btn-primary">
                  <div>
                    <FontAwesomeIcon icon={faList} className="fa-5x"/>
                    <br />
                    <br />
                    Browse
                  </div>
                </LinkContainer>
              </Col>
              <Col xs={3} className="align-self-center">
                <LinkContainer to="/search" className="btn btn-squared-default-plain btn-primary">
                  <div>
                    <FontAwesomeIcon icon={faSearch} className="fa-5x"/>
                    <br />
                    <br />
                    Search
                  </div>
                </LinkContainer>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    );
  }
}

