import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";


function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      console.log(e);
    }
    setIsAuthenticating(false);
  }

//  async function handleLogout() {
//    await Auth.signOut();
//    userHasAuthenticated(false);
//    props.history.push("/login");
//  }

  return (
    !isAuthenticating &&
    <div className="App container">
      {isAuthenticated ? ( <> </>
      ): ( <> </> )}

      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </div>
  );
}

export default withRouter(App);