const dev = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "recipes-penguinseatcheese-dev"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://recipes-dev.penguinseatcheese.co.uk"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_AysCxB5np",
    APP_CLIENT_ID: "4hodvttsv09set3f00e46b4t9p",
    IDENTITY_POOL_ID: "eu-west-1:7d1a4f5c-0b83-4df8-815b-83ba5fd425a1"
  }
};

const prod = {
  s3: {
    REGION: "eu-west-1",
    BUCKET: "recipes-penguinseatcheese-prod"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://recipes-api.penguinseatcheese.co.uk"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_3dOmBwwQp",
    APP_CLIENT_ID: "qjh54tfk14eocd6olm8nsuf3b",
    IDENTITY_POOL_ID: "eu-west-1:7812bf4b-83cb-4739-ba00-bf0d78db615b"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};
