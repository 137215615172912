import React, { PureComponent } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";


export default class Steps extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      steps: {1:'',2:'',3:''}
    };
    if (props.steps) {
      this.state.steps = props.steps;
    }
  }

  addStep() {
    var stepCount = Object.keys(this.state.steps).length+1;
    this.setState({steps: {...this.state.steps, [stepCount]: ''}});
    this.props.stepStateMirror({...this.state.steps, [stepCount]: ''});
  }

  updateStep = stepNum => (event) => {
    let stepText = event.target.value;
    this.setState({steps: {...this.state.steps, [stepNum]: stepText}});
    this.props.stepStateMirror({...this.state.steps, [stepNum]: stepText});
  }

  renderSteps() {
    var stepNum;
    let stepsHtml = [];
    for (stepNum=1; stepNum < Object.keys(this.state.steps).length+1; stepNum++) {
      stepsHtml.push(
        <FormGroup className="row" controlId="content" key={'stepKey'+stepNum}>
          <div className="col-sm-12">
            <FormControl type='text' name={'step'+stepNum} defaultValue={this.state.steps[stepNum]} onChange={this.updateStep(stepNum)} />
          </div>
        </FormGroup>
      );
    }
    return stepsHtml;
  }

  render() {
    return (
      <>
        {this.renderSteps()}
        <Button name="buttonAddStep" onClick={this.addStep.bind(this)}>Add step</Button>
      </>
    );
  }
}