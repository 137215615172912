import React, { PureComponent } from "react";
import { Button, FormGroup, FormLabel, FormControl, Card } from "react-bootstrap";


export default class Ingredients extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      ing: {
        1: {
          groupName:'',
          ing: {
            1: {qty:'',unit:'',ing:'',prep:''},
            2: {qty:'',unit:'',ing:'',prep:''},
            3: {qty:'',unit:'',ing:'',prep:''}
          }
        }
      }
    };
    if (props.ingredients) {
      this.state.ing = props.ingredients;
    }
  }

  units = ['grams', 'cups', 'ml', 'oz', 'floz', 'whole'];

  addGroup() {
    var groupNum = Object.keys(this.state.ing).length+1;
    this.setState({ing: {...this.state.ing, [groupNum]: {groupName:'', ing: {}}}});
    this.props.ingredientStateMirror({...this.state.ing, [groupNum]: {groupName:'', ing: {}}});
  }

  addIngredient = (groupNum) => (event) => {
    var ingNum = Object.keys(this.state.ing[groupNum]['ing']).length+1
    let ingredients = this.state.ing;
    ingredients[groupNum]['ing'][ingNum] = {qty:'',unit:'',ing:'',prep:''};
    this.setState({ing: ingredients});
    this.props.ingredientStateMirror(ingredients);
  }

  updateIngredient = (groupNum, ingredientNum, fieldType) => (event) => {
    let fieldVal = event.target.value;
    let ingredients = this.state.ing;
    ingredients[groupNum]['ing'][ingredientNum][fieldType] = fieldVal;
    this.setState({ing: ingredients});
    this.props.ingredientStateMirror(ingredients);
  }

  updateGroupName = (groupNum) => (event) => {
    let fieldVal = event.target.value;
    let ingredients = this.state.ing;
    ingredients[groupNum]['groupName'] = fieldVal;
    this.setState({ing: ingredients});
    this.props.ingredientStateMirror(ingredients);
  }

  render() {
    var groupNum;
    var ingNum;
    let ingredientsHtml = [];
    let groupHtml = [];
    for (groupNum=1; groupNum < Object.keys(this.state.ing).length+1; groupNum++) {
      groupHtml = [];
      for (ingNum=1; ingNum < Object.keys(this.state.ing[groupNum]['ing']).length+1; ingNum++) {
        groupHtml.push(
          <div className="row" key={'ingGroup'+groupNum+'Ing'+ingNum}>
            <div className="col-sm-2">
              <FormControl type='text' name={'ing'+groupNum+'-'+ingNum+'qty'} defaultValue={this.state.ing[groupNum]['ing'][ingNum]['qty']} onChange={this.updateIngredient(groupNum, ingNum, 'qty')} />
            </div>
            <div className="col-sm-3">
              <FormControl type='text' name={'ing'+groupNum+'-'+ingNum+'unit'} defaultValue={this.state.ing[groupNum]['ing'][ingNum]['unit']} onChange={this.updateIngredient(groupNum, ingNum, 'unit')} />
            </div>
            <div className="col-sm-4">
              <FormControl type='text' name={'ing'+groupNum+'-'+ingNum+'ing'} defaultValue={this.state.ing[groupNum]['ing'][ingNum]['ing']} onChange={this.updateIngredient(groupNum, ingNum, 'ing')} />
            </div>
            <div className="col-sm-3">
              <FormControl type='text' name={'ing'+groupNum+'-'+ingNum+'prep'} defaultValue={this.state.ing[groupNum]['ing'][ingNum]['prep']} onChange={this.updateIngredient(groupNum, ingNum, 'prep')} />
            </div>
          </div>
        );
      }
      ingredientsHtml.push(
        <div key={'ingGroup'+groupNum}>
        <Card>
          <Card.Body>
          <FormGroup className="row" controlId="content">
            <FormLabel className="col-sm-2 col-form-label">Group Name</FormLabel>
            <div className="col-sm-10">
              <FormControl type='text' name={'ing'+groupNum+'-group'} defaultValue={this.state.ing[groupNum]['groupName']} onChange={this.updateGroupName(groupNum)}/>
            </div>
          </FormGroup>
          <FormGroup controlId="content">
            <div className="row">
              <FormLabel className="col-sm-2 col-form-label"><b>Qty</b></FormLabel>
              <FormLabel className="col-sm-3 col-form-label"><b>Unit</b></FormLabel>
              <FormLabel className="col-sm-4 col-form-label"><b>Ingredient</b></FormLabel>
              <FormLabel className="col-sm-3 col-form-label"><b>Preperation</b></FormLabel>
            </div>
            {groupHtml}
            <Button name={"buttonAddIng"+groupNum} onClick={this.addIngredient(groupNum)}>Add ingredient</Button>
          </FormGroup>
          </Card.Body>
        </Card>
        </div>
      );
    }
    ingredientsHtml.push(<Button key='ingGroupButton' name="buttonAddIngGroup" onClick={this.addGroup.bind(this)}>Add group</Button>);
    return ingredientsHtml;
  }
}
