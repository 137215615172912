import React from 'react'
import ReactTags from 'react-tag-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// Each entry in tags and suggestions needs to be of the form:
//     { name: "Entry" }
// Lots of functions to constrain this to this class
// All external data in/out is simply a list of ["Entry"]


function TagComponent({ tag, removeButtonText, onDelete }) {
  return (
    <button type='button' title={removeButtonText}>
      {tag.name} <FontAwesomeIcon icon={faTimes} className="fa-fw" onClick={onDelete}/>
    </button>
  )
}

function SuggestionComponent({ item, query }) {
  return (
    <span id={item.id} className={item.name === query ? 'match' : 'no-match'}>
      {item.name}
    </span>
  )
}


function dict2List(d) {
  var l = []
  var entry
  for (entry in d) {
    l.push(d[entry].name)
  }
  return (l)
}

function findMissingItems(superset, subset) {
  // Find missing items between two LISTS
  var missing = []
  var entry
  for (entry in superset) {
    if (!subset.includes(superset[entry])) {
      missing.push(superset[entry])
    }
  }
  return (missing)
}

function updateDictWithNewList(d, l) {
  // Convert state to list, find new entries, add new entries to state
  var missing = findMissingItems(l, dict2List(d));
  var m;
  for (m in missing) {
    d.push({name: missing[m]})
  }
  return (d)
}

export default class App extends React.Component {
  constructor (props) {
    super(props)
    this.props = props;
    this.allowNew = props.allowNew
    this.state = {
      tags: [],
      suggestions: [],
      updateCount: 0
    }
    this.reactTags = React.createRef()
  }

  static getDerivedStateFromProps(props, state) {
    // Update the state from the supplied props
    if (state.updateCount < 2) {
      // Only update twice (initial and once info gets pulled from API)!
      state.updateCount += 1
      if (props.tags) {
        state.tags = updateDictWithNewList(state.tags, props.tags)
      }
      if (props.suggestions) {
        state.suggestions = updateDictWithNewList(state.suggestions, props.suggestions)
      }
    } else {
      if (props.newTag) {
        state.tags = updateDictWithNewList(state.tags, [props.newTag])
      }
    }
    return (state)
  }

  onDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags: tags })
    this.props.tagStateMirror(dict2List(tags))
  }

  onAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags: tags })
    this.props.tagStateMirror(dict2List(tags))
  }

  render () {
    return (
      <ReactTags
        ref={this.reactTags}
        tags={this.state.tags}
        allowNew={this.allowNew}
        allowBackspace={false}
        tagComponent={TagComponent}
        suggestionComponent={SuggestionComponent}
        suggestions={this.state.suggestions}
        onDelete={this.onDelete.bind(this)}
        onAddition={this.onAddition.bind(this)} />
    )
  }
}

