import React, { PureComponent } from "react";
import { API, Auth, Storage } from "aws-amplify";
import { Nav, Container, ListGroup, ListGroupItem, Row, Col, Card, Modal, Button, FormLabel, FormGroup } from "react-bootstrap";
//import LoaderButton from "../components/LoaderButton";
//import { s3Upload } from "../libs/awsLib";
//import config from "../config";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBreadSlice, faInfoCircle, faBalanceScale, faEdit, faArrowAltCircleLeft, faUndo } from '@fortawesome/free-solid-svg-icons'
import "./Recipes.css";


export default class SingleRecipe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showInfo: false,
      showScale: false,
      recipeId: props.match.params.id,
      info: {
        title: '',
        description: '',
        yield_: 0,
        made: false,
        prepTime: 0,
        cookTime: 0,
        keywords: [],
        source_: '',
        notes: ''
      },
      steps: {},
      ingredients: {},
      suggestions: [],
      highlights: {},
      imgUrl: null
    };
  }

  async componentDidMount() {
    await Auth.currentSession();
    try {
      var recipe = await API.get("recipes", `/recipe/${this.state.recipeId}`);
      var infoDetails = {title: recipe.title,
                         description: recipe.description,
                         yield_: recipe.yield_,
                         made: recipe.made,
                         prepTime: recipe.prepTime,
                         cookTime: recipe.cookTime,
                         keywords: recipe.keywords,
                         source_: recipe.source_,
                         notes: recipe.notes,
                         image: recipe.image}
      this.setState({info: infoDetails,
                     ingredients: recipe.ingredients,
                     steps: recipe.steps,
                     isLoading: false});
      this.resetHighlightState();
      const tags = await API.get("recipes", "/tags");
      // Get image
      var imgUrl = null;
      if (recipe.image) {
        imgUrl = await Storage.vault.get(this.state.recipeId);
      }
      this.setState({suggestions: tags, imgUrl: imgUrl});
    } catch (e) {
      alert(e);
    }
  }

  resetHighlightState() {
    var highlights = {}
    var i = 0;
    var j = 0;
    for (i=0; i<Object.keys(this.state.steps).length; i++) {
      highlights['step_'+i] = 0;
    }
    var sorted_groups = Object.keys(this.state.ingredients).sort()
    for (i=0; i<Object.keys(this.state.ingredients).length; i++) {
      for (j=0; j<Object.keys(this.state.ingredients[sorted_groups[i]].ing).length; j++) {
        highlights['ing_'+i+'_'+j] = 0;
      }
    }
    this.setState({highlights: highlights});
  }

  handleShowScale() {
    this.setState({showScale: true});
  }

  handleCloseScale() {
    this.setState({showScale: false});
  }

  handleShowInfo() {
    this.setState({showInfo: true});
  }

  handleCloseInfo() {
    this.setState({showInfo: false});
  }

  handleBack() {
    this.props.history.goBack()
  }

  handleItemClick(itemName) {
    var i = this.state.highlights[itemName]
    i += 1;
    i %= 3;
    this.setState({highlights: {...this.state.highlights, [itemName]: i}})
  }

  makeItemClassName(itemName) {
    if (this.state.highlights[itemName] === 0) {
      return "list-group-item-plain"
    }
    if (this.state.highlights[itemName] === 1) {
      return "list-group-item-highlight"
    }
    if (this.state.highlights[itemName] === 2) {
      return "list-group-item-grey"
    }
  }

  renderIngredientGroup(groupIdx, group) {
    var sorted_ingredients = Object.keys(group).sort()
    return sorted_ingredients.map((ing, i) =>
        <ListGroupItem className={"ingredient " + this.makeItemClassName("ing_"+groupIdx+"_"+i)} key={"ing_num_"+groupIdx+"_"+i} onClick={this.handleItemClick.bind(this, "ing_"+groupIdx+"_"+i)}>
          {group[ing].qty} {group[ing].unit} {group[ing].ing} {group[ing].prep}
        </ListGroupItem>
    );
  }

  renderIngredients() {
    var sorted_groups = Object.keys(this.state.ingredients).sort()
    return sorted_groups.map((group, i) =>
        <div key={"ing_group_num_"+i}>
          <Card bg="primary">
            <Card.Header>{sorted_groups.length === 1 ? "Ingredients" : this.state.ingredients[group].groupName}</Card.Header>
            <ListGroup>
              {this.renderIngredientGroup(i, this.state.ingredients[group].ing)}
            </ListGroup>
          </Card>
          <br/>
        </div>
    );
  }

  renderSteps() {
    var sorted_steps = Object.keys(this.state.steps).sort()
    return sorted_steps.map((step, i) =>
          <ListGroupItem className={"recipeStep " + this.makeItemClassName("step_"+i)} key={"step_num_"+i} onClick={this.handleItemClick.bind(this, "step_"+i)}>
            {this.state.steps[step]}
          </ListGroupItem>
    );
  }

  renderImage() {
    if (this.state.imgUrl) {
      return (<img src={this.state.imgUrl} alt="The delicious output"></img>)
    } else {
      return (<></>)
    }
  }

  renderRecipe() {
    return (
      <>
        <Row>
          <Col md={10}>
            <h1>{this.state.info.title}</h1>
            <i>{this.state.info.description}</i>
          </Col>
          <Col md={2}>
            <i>Makes {this.state.info.yield_}</i>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {this.renderIngredients()}
          </Col>
          <Col md={8}>
        <ListGroup>
            {this.renderSteps()}
        </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {this.renderImage()}
          </Col>
        </Row>

        <Modal show={this.state.showInfo} onHide={this.handleCloseInfo.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Recipe Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Title</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.title}</FormLabel>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Makes</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.yield_}</FormLabel>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Prep Time</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.prepTime} mins</FormLabel>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Cook Time</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.cookTime} mins</FormLabel>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Keywords</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.keywords.join(', ')}</FormLabel>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Source</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.source}</FormLabel>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-4 col-form-label">Notes</FormLabel>
              <FormLabel className="col-sm-8 col-form-label">{this.state.info.notes}</FormLabel>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleCloseInfo.bind(this)}>Ok</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showScale} onHide={this.handleCloseScale.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Settings</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>TODO: SET QUANTITY AND UNITS.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseScale.bind(this)}>Close</Button>
            <Button variant="primary">Save changes</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  renderPage() {
    return (
      <div className="Home">
        {!this.state.isLoading && this.renderRecipe()}
      </div>
    )
  }

  render() {
    return (
      <Container>
        <Row>
          <div className="col-xs-05">
            <Nav className="d-none d-sm-block bg-secondary sidebar">
              <div className="sidebar-sticky"></div>
              <Nav.Item>
                <LinkContainer to="/"><Nav.Link><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/></Nav.Link></LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={this.handleBack.bind(this)}><FontAwesomeIcon icon={faArrowAltCircleLeft} className="fa-fw"/></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <LinkContainer to={"/recipes/edit/"+this.state.recipeId}><Nav.Link><FontAwesomeIcon icon={faEdit} className="fa-fw"/></Nav.Link></LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={this.handleShowInfo.bind(this)}><FontAwesomeIcon icon={faInfoCircle} className="fa-fw"/></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={this.handleShowScale.bind(this)}><FontAwesomeIcon icon={faBalanceScale} className="fa-fw"/></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={this.resetHighlightState.bind(this)}><FontAwesomeIcon icon={faUndo} className="fa-fw"/></Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-xs-115">
            {this.renderPage()}
          </div>
        </Row>
      </Container>
    );
  }
}
