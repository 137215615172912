import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Container, Row, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBreadSlice } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from "react-router-bootstrap";
import { API, Auth } from "aws-amplify";
import "./Home.css";

export default function Home(props) {
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }
      await Auth.currentSession();
      try {
        const recipes = await loadRecipes();
        console.log(recipes);
        setRecipes(recipes);
      } catch (e) {
        alert(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [props.isAuthenticated]);

  function loadRecipes() {
    return API.get("recipes", "/recipe");
  }

  function renderRecipesList(recipes) {
    return recipes.map((recipe, i) =>
        <LinkContainer key={recipe.recipeId} to={`/recipes/view/${recipe.recipeId}`}>
          <ListGroupItem>
            <h5 className="mb-1">{recipe.title.trim()}</h5>
            {recipe.description}
            <i>{recipe.keywords}</i>
          </ListGroupItem>
        </LinkContainer>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Scratch</h1>
        <p>A simple note taking app</p>
      </div>
    );
  }

  function renderPage() {
    return (
      <div className="recipes">
        <ListGroup>
          {!isLoading && renderRecipesList(recipes)}
        </ListGroup>
      </div>
    );
  }

  function render() {
    return (
      <Container>
        <Row>
          <div className="col-xs-05">
            <Nav className="d-none d-sm-block bg-secondary sidebar">
              <div className="sidebar-sticky"></div>
              <Nav.Item>
                <Nav.Link href="/"><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/></Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-xs-115">
            {renderPage()}
          </div>
        </Row>
      </Container>
    );
  }

  return (
    <div className="Home">
      {props.isAuthenticated ? render() : renderLander()}
    </div>
  );
}


// {"Created: " + new Date(note.createdAt).toLocaleString()}