import React, { PureComponent } from "react";
import { Modal, Button, Card, FormGroup, FormControl, FormLabel, Row, Nav, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBreadSlice, faAlignLeft } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Ingredients from "../components/Ingredients";
import Steps from "../components/Steps";
import Tags from "../components/Tags";
import { API, Auth } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import "./Recipes.css";


// We store the steps and ingredients here so that they can be accessed during a submit event.
// But the child elements also store them, because the re-render takes place based on their
// state changing.
// It means we're storing everything twice, but that's (probably) fine.

// TODO: Keywords, made - <Form.Check aria-label="option 1" />
// TODO: Handle submit success/error (toast?)


export default class CreateRecipe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: {
        title: '',
        description: '',
        yield_: 0,
        made: false,
        prepTime: 0,
        cookTime: 0,
        keywords: [],
        source_: '',
        notes: '',
        image: false
      },
      steps: {},
      ingredients: {},
      suggestions: [],
      showParserModal: false,
      parser: {
        steps: '',
        ingredients: [''],
        ingredientTitles: ['']
      }
    };
    this.fileRef = React.createRef();
    this.stepsRef = React.createRef();
    this.ingredientsRef = React.createRef();
  }

  async componentDidMount() {
   await Auth.currentSession();
    try {
      const tags = await API.get("recipes", "/tags");
      this.setState({suggestions: tags});
    } catch (e) {
      alert(e);
    }
  }

  setIsLoading(loading) {
    this.setState({isLoading: loading});
  }

  validateForm() {
    return this.state.form.title.length > 0;
  }

  handleFileChange = event => {
    this.fileRef.current = event.target.files[0];
    this.setState({form: {...this.state.form, image: true}});
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setIsLoading(true);
    try {
      var resp = await this.createRecipe();
      if (this.fileRef.current) {
        await s3Upload(this.fileRef.current, resp.recipeId);
      }
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setIsLoading(false);
    }
  }

  createRecipe() {
    return API.post("recipes",
                    "/recipe",
                    {
                      body: {
                        info: this.state.form,
                        ingredients: this.state.ingredients,
                        steps: this.state.steps
                      }
                    }
    );
  }

  async doParse() {
    var parsedData = await API.post("recipes",
                    "/parse",
                    {
                      body: {
                        ingredients: this.state.parser.ingredients,
                        ingredientTitles: this.state.parser.ingredientTitles,
                        steps: this.state.parser.steps
                      }
                    }
                  );
    this.ingredientsRef.current.setState({ing: parsedData.ingredients});
    this.stepsRef.current.setState({steps: parsedData.steps});
    this.setState({steps: parsedData.steps,
                   ingredients: parsedData.ingredients,
                   showParserModal: false,
                   parser: {steps: '', ingredients: [''], ingredientTitles: ['']}
    });
  }

  handleShowParser() {
    this.setState({showParserModal: true});
  }

  addParserIngredients() {
    var ings = this.state.parser.ingredients;
    var titles = this.state.parser.ingredientTitles;
    ings.push('');
    titles.push('');
    this.setState({parser: {...this.state.parser, ingredients: ings, ingredientTitles: titles}});
  }

  handleCloseParser() {
    this.setState({showParserModal: false});
  }

  ingredientStateMirror(ingredientState) {
    this.setState({ingredients: ingredientState});
  }

  stepStateMirror(stepState) {
    this.setState({steps: stepState});
  }

  tagStateMirror(tagState) {
    this.setState({form: {...this.state.form, keywords: tagState}});
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    this.setState({form: {...this.state.form, [fieldName]: fieldVal}});
  }

  handleParserChange(event) {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    if (fieldName === 'parserSteps') {
      this.setState({parser: {...this.state.parser, 'steps': fieldVal}});
    }
    if (fieldName.startsWith('parserIngredients')) {
      var fieldSplit = fieldName.split('-');
      if (fieldSplit[1] === 'ing') {
        var ings = this.state.parser.ingredients;
        ings[fieldSplit[2]] = fieldVal;
        this.setState({parser: {...this.state.parser, 'ingredients': ings}});
      }
      if (fieldSplit[1] === 'title') {
        var titles = this.state.parser.ingredientTitles;
        titles[fieldSplit[2]] = fieldVal;
        this.setState({parser: {...this.state.parser, 'ingredientTitles': titles}});
      }
    }
  }

  renderParserIngredients() {
    if (this.state.parser.ingredients.length === 1) {
      return (
          <FormGroup className="row" controlId="content">
            <FormLabel className="col-sm-12 col-form-label">Ingredients</FormLabel>
            <div className="col-sm-12">
              <FormControl
                as='textarea'
                name='parserIngredients-ing-0'
                rows="5"
                defaultValue={this.state.parser.ingredients[0]}
                onChange={this.handleParserChange.bind(this)}
              />
            </div>
          </FormGroup>
      );
    } else {
      return this.state.parser.ingredients.map((ing, i) =>
          <FormGroup className="row" controlId="content" key={"parserIngredients-"+i}>
            <FormLabel className="col-sm-5 col-form-label">Ingredient Group {i+1}</FormLabel>
            <div className="col-sm-7">
              <FormControl
                type='text'
                name={'parserIngredients-title-'+i}
                defaultValue={this.state.parser.ingredientTitles[i]}
                onChange={this.handleParserChange.bind(this)}
              />
            </div>
            <div className="col-sm-12">
              <FormControl
                as='textarea'
                name={'parserIngredients-ing-'+i}
                rows="5"
                defaultValue={this.state.parser.ingredients[i]}
                onChange={this.handleParserChange.bind(this)}
              />
            </div>
          </FormGroup>
      );
    }
  }

  renderParserModel() {
    return (
      <Modal show={this.state.showParserModal} onHide={this.handleCloseParser.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Parse recipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {this.renderParserIngredients()}
            <FormGroup className="row" controlId="content">
              <div className="col-sm-12">
                <Button variant="secondary" onClick={this.addParserIngredients.bind(this)}>
                  Add ingredients section
                </Button>
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-12 col-form-label">Steps</FormLabel>
              <div className="col-sm-12">
                <FormControl
                  as='textarea'
                  name='parserSteps'
                  rows="5"
                  defaultValue={this.state.parser.steps}
                  onChange={this.handleParserChange.bind(this)}
                />
              </div>
            </FormGroup>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseParser.bind(this)}>
            Close
          </Button>
          <Button variant="primary" onClick={this.doParse.bind(this)}>
            Parse
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderPage() {
    return (
     <div className="p-3">
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Card>
          <Card.Body>
            <h3>Recipe details</h3>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Title</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='text'
                  name='title'
                  defaultValue={this.state.form.title}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Description</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='text'
                  name='description'
                  defaultValue={this.state.form.description}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Makes</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='number'
                  name='yield_'
                  defaultValue={this.state.form.yield_}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Prep Time</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='number'
                  name='prepTime'
                  defaultValue={this.state.form.prepTime}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Cook Time</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='number'
                  name='cookTime'
                  defaultValue={this.state.form.cookTime}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Made</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  as='select'
                  name='made'
                  defaultValue={this.state.form.made}
                  onChange={this.handleChange.bind(this)}
                >
                  <option>Yes</option>
                  <option>No</option>
                </FormControl>
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Keywords</FormLabel>
              <div className="col-sm-10">
                <Tags allowNew={true} tagStateMirror={this.tagStateMirror.bind(this)} tags={this.state.form.keywords} suggestions={this.state.suggestions}/>
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Source</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  type='text'
                  name='source_'
                  defaultValue={this.state.form.source_}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
            <FormGroup className="row" controlId="content">
              <FormLabel className="col-sm-2 col-form-label">Notes</FormLabel>
              <div className="col-sm-10">
                <FormControl
                  as='textarea'
                  name='notes'
                  rows="2"
                  defaultValue={this.state.form.notes}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </FormGroup>
          </Card.Body>
        </Card>

        &nbsp;

        <Card>
          <Card.Body>
            <h3>Ingredients</h3>
            <Ingredients ref={this.ingredientsRef} ingredientStateMirror={this.ingredientStateMirror.bind(this)} />
          </Card.Body>
        </Card>

        &nbsp;

        <Card>
          <Card.Body>
            <h3>Method</h3>
            <Steps ref={this.stepsRef} stepStateMirror={this.stepStateMirror.bind(this)} />
          </Card.Body>
        </Card>

        &nbsp;

        <Card>
          <Card.Body>
            <h3>Image</h3>
            <FormControl onChange={this.handleFileChange.bind(this)} type="file" />
          </Card.Body>
        </Card>

        &nbsp;

        <LoaderButton
          block
          type="submit"
          bssize="large"
          bsstyle="primary"
          isLoading={this.state.isLoading}
          disabled={!this.validateForm()}
        >
          Create
        </LoaderButton>
      </form>

    </div>
    );
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <div className="col-xs-05">
              <Nav className="d-none d-sm-block bg-secondary sidebar">
                <div className="sidebar-sticky"></div>
                <Nav.Item>
                  <LinkContainer to="/"><Nav.Link><FontAwesomeIcon icon={faBreadSlice} className="fa-fw"/></Nav.Link></LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={this.handleShowParser.bind(this)}><FontAwesomeIcon icon={faAlignLeft} className="fa-fw"/></Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-xs-115">
              {this.renderPage()}
            </div>
          </Row>
        </Container>

        {this.renderParserModel()}
      </>
    );
  }
}
