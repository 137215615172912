import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import MainPage from "./containers/MainPage";
import NotFound from "./containers/NotFound";
import Search from "./containers/Search";
import ChangePassword from "./containers/ChangePassword";
import ResetPassword from "./containers/ResetPassword";
import CreateRecipe from "./containers/CreateRecipe";
import ListRecipes from "./containers/ListRecipes";
import EditRecipe from "./containers/EditRecipe";
import Recipes from "./containers/SingleRecipe";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/main" exact component={MainPage} appProps={appProps} />
      <AuthenticatedRoute path="/search" exact component={Search} appProps={appProps} />
      <AuthenticatedRoute path="/recipes/new" exact component={CreateRecipe} appProps={appProps} />
      <AuthenticatedRoute path="/recipes" exact component={ListRecipes} appProps={appProps} />
      <AuthenticatedRoute path="/recipes/view/:id" exact component={Recipes} appProps={appProps} />
      <AuthenticatedRoute path="/recipes/edit/:id" exact component={EditRecipe} appProps={appProps} />
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}